import React from "react";

const AnnotationTypeImage = ({fill}) => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_463_7410)">
      <path
        d="M8.89361 14.5317C9.39003 14.5317 9.86612 14.3385 10.2171 13.9947C10.5682 13.6509 10.7654 13.1846 10.7654 12.6983C10.7654 12.2121 10.5682 11.7458 10.2171 11.402C9.86612 11.0582 9.39003 10.865 8.89361 10.865C8.39718 10.865 7.92109 11.0582 7.57007 11.402C7.21905 11.7458 7.02184 12.2121 7.02184 12.6983C7.02184 13.1846 7.21905 13.6509 7.57007 13.9947C7.92109 14.3385 8.39718 14.5317 8.89361 14.5317Z"
        fill={fill}
        className="dark:group-hover:fill-white dark:group-active:fill-white group-hover:fill-[#3F2193] group-active:fill-[#3F2193]"
      />
      <path
        d="M20.7481 19.4206C20.7481 20.0689 20.4852 20.6906 20.0171 21.149C19.5491 21.6075 18.9143 21.865 18.2524 21.865H5.774C5.11211 21.865 4.47732 21.6075 4.00929 21.149C3.54126 20.6906 3.27832 20.0689 3.27832 19.4206V9.64279C3.27832 8.99491 3.54091 8.37352 4.00841 7.91517C4.4759 7.45682 5.11004 7.199 5.77151 7.19835C5.77151 6.55004 6.03444 5.92829 6.50247 5.46987C6.97051 5.01144 7.60529 4.75391 8.26719 4.75391H20.7456C21.4075 4.75391 22.0423 5.01144 22.5103 5.46987C22.9783 5.92829 23.2413 6.55004 23.2413 7.19835V16.9761C23.2413 17.624 22.9787 18.2454 22.5112 18.7037C22.0437 19.1621 21.4096 19.4199 20.7481 19.4206ZM20.7456 5.97613H8.26719C7.93624 5.97613 7.61885 6.1049 7.38483 6.33411C7.15082 6.56332 7.01935 6.87419 7.01935 7.19835H18.2524C18.9143 7.19835 19.5491 7.45589 20.0171 7.91431C20.4852 8.37273 20.7481 8.99448 20.7481 9.64279V18.1983C21.0786 18.1977 21.3954 18.0686 21.6288 17.8395C21.8623 17.6104 21.9934 17.2998 21.9934 16.9761V7.19835C21.9934 6.87419 21.862 6.56332 21.628 6.33411C21.3939 6.1049 21.0765 5.97613 20.7456 5.97613ZM5.774 8.42057C5.44305 8.42057 5.12566 8.54934 4.89165 8.77855C4.65763 9.00776 4.52616 9.31864 4.52616 9.64279V19.4206L7.82795 16.5434C7.92994 16.4439 8.06393 16.382 8.20722 16.3682C8.3505 16.3544 8.49425 16.3895 8.61409 16.4677L11.9333 18.6347L16.5628 14.1002C16.6554 14.0095 16.7747 13.9496 16.9039 13.929C17.0331 13.9084 17.1657 13.9281 17.2828 13.9853L19.5003 16.365V9.64279C19.5003 9.31864 19.3688 9.00776 19.1348 8.77855C18.9008 8.54934 18.5834 8.42057 18.2524 8.42057H5.774Z"
        fill={fill}
        className="dark:group-hover:fill-white dark:group-active:fill-white group-hover:fill-[#3F2193] group-active:fill-[#3F2193]"
      />
    </g>
    <defs>
      <clipPath id="clip0_463_7410">
        <rect
          width="25.6667"
          height="25.6666"
          fill="white"
          transform="translate(0.166016 0.871094)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AnnotationTypeImage;
