import React, { useEffect, useState } from "react";
import { navigate } from "gatsby-link";
import { useMediaQuery } from "react-responsive";
import WorkflowIcon1 from "../../assets/icons/NewAnnotate/WorkflowIcon1";
import WorkflowIcon2 from "../../assets/icons/NewAnnotate/WorkflowIcon2";
import WorkflowIcon3 from "../../assets/icons/NewAnnotate/WorkflowIcon3";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const EncordAnnotateProduct = ({
  encordProducts,
  MobileScreenLabel,
  cardIcons,
  DesktopScreenLabel,
}) => {
  const [activeProduct, setActiveProduct] = useState(0);
  const [target, setTarget] = useState(null);
  const ismobile = useMediaQuery({ minWidth: 475 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const WorkflowIcons = cardIcons || [
    {
      icon: <WorkflowIcon1 />,
    },
    {
      icon: <WorkflowIcon2 />,
    },
    {
      icon: <WorkflowIcon3 />,
    },
    {
      icon: <WorkflowIcon3 />,
    },
    {
      icon: <WorkflowIcon3 />,
    },
  ];

  useEffect(() => {
    // Increment activeProduct to move to the next index
    const interval = setInterval(() => {
      setActiveProduct((prevIndex) =>
        prevIndex === encordProducts.items.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [activeProduct, encordProducts.items.length]);

  return (
    <section
      className={`flex flex-col items-center mt-6 lg:bg-white bg-gray-2800 lg:flex-row rounded-xl sm:rounded-2xl border overflow-hidden lg:p-0 p-4 sm:p-5 `}
    >
      <div className={`lg:max-w-[458px] text-left text-gray-600 bg-gray-2800`}>
        {!ismobile ? (
          <h2
            className={`text-xs tracking-widest_1 opacity-50 font-medium uppercase p-4 sm:p-5`}
          >
            {MobileScreenLabel}
          </h2>
        ) : (
          <h2
            className={`text-xs tracking-widest_1 opacity-50 font-medium uppercase p-4 sm:p-5`}
          >
            {DesktopScreenLabel}
          </h2>
        )}

        <div className="flex flex-col lg:max-w-md md:justify-start xl:pb-0 lg:pb-5">
          {encordProducts?.items?.map(
            (
              {
                product_icon,
                product_heading,
                product_sub_heading,
                product_description,
                cta_link,
                product_video,
                product_image,
              },
              index
            ) => {
              return (
                <>
                  <div
                    className={` p-4 group sm:p-5 gap-2.5 duration-200 ease-linear overflow-hidden hover: $ ${
                      activeProduct !== index
                        ? "bg-gray-2800"
                        : `bg-white lg:rounded-none rounded-xl`
                    }`}
                    key={index}
                    onClick={() => {
                      isDesktop
                        ? cta_link?.url && navigate(cta_link?.url)
                        : setActiveProduct(index);
                    }}
                    onMouseEnter={() => {
                      setTarget(product_heading?.text);
                      setActiveProduct(index);
                    }}
                    onMouseLeave={() => {
                      setTarget(null);
                      setActiveProduct(index);
                    }}
                  >
                    <div className="">
                      <div className="flex flex-col gap-2.5">
                        <div className={`${activeProduct !== index}`}>
                          {React.cloneElement(WorkflowIcons[index].icon, {
                            fill:
                              activeProduct == index ? "#5E44FF" : "#595959",
                          })}
                        </div>

                        <div className="w-full gap-2.5">
                          <div className="flex">
                            <h3
                              className={`text-xl font-semibold  ${activeProduct == index ? "text-purple-1100" : "text-gray-600"}  font-manrope tracking-tightest`}
                            >
                              {product_heading?.text}
                            </h3>
                          </div>
                          <p className="text-base font-inter tracking-tight_1">
                            {product_sub_heading?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p
                        className={`font-inter text-base tracking-tighter_3 ${activeProduct == index ? "text-gray-2500" : "text-gray-1200"}`}
                      >
                        {product_description?.text}
                      </p>
                      <div
                        className={` lg:hidden pt-5 ${
                          activeProduct == index
                            ? "block lg:block"
                            : " hidden lg:block"
                        }`}
                      >
                        {encordProducts?.items[activeProduct]?.product_video
                          ?.url ? (
                          <div className="justify-center flex duration-[2s] ease-in">
                            {/* <Video
                              src={product_video?.url}
                              classes="rounded-2xl border-[0.3px] w-full h-auto border-gray-1600"
                              posterImage={posters[activeProduct]}
                            /> */}
                          </div>
                        ) : (
                          <GatsbyImage
                            image={getImage(product_image)}
                            alt={
                              product_image?.alt ||
                              product_heading ||
                              "product_image"
                            }
                            loading="lazy"
                            className={`rounded-2xl border-[0.3px] border-gray-1600`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          )}
        </div>
      </div>
      {encordProducts?.items[activeProduct]?.product_video?.url ? (
        <div className="justify-center xl:px-7.5 py-10 lg:px-5 p-14  duration-[1s] ease-in lg:flex hidden justify-self-center m-auto">
          {/* <Video
            src={encordProducts?.items[activeProduct]?.product_video?.url}
            classes="xl:h-[400px] lg:h-[370px] h-72 rounded-2xl shadow-card"
            posterImage={posters[activeProduct]}
          /> */}
        </div>
      ) : (
        <>
          {isDesktop && (
            <GatsbyImage
              image={getImage(
                encordProducts?.items[activeProduct]?.product_image
              )}
              width={0}
              height={0}
              loading="lazy"
              alt="activeProduct image"
              className={`max-w-[450px] xl:max-w-[614px] xl:max-h-[540px] h-full flex-shrink-0 rounded-2xl mx-auto w-auto lg:block hidden`}
            />
          )}
        </>
      )}
    </section>
  );
};
export default EncordAnnotateProduct;
