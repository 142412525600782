import React from "react";

const CardIcon2 = ({ fill }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8708 15.3262L14.5915 18.2324L13.3874 16.6973C13.3654 16.6693 13.3374 16.6466 13.3054 16.631C13.2735 16.6154 13.2384 16.6073 13.2028 16.6073C13.1672 16.6073 13.1321 16.6154 13.1001 16.631C13.0682 16.6466 13.0401 16.6693 13.0182 16.6973L10.0944 20.4238C10.0673 20.4584 10.0506 20.4998 10.046 20.5435C10.0414 20.5871 10.0491 20.6311 10.0684 20.6706C10.0876 20.71 10.1176 20.7432 10.1548 20.7664C10.1921 20.7896 10.2351 20.8019 10.279 20.8018H21.0573C21.2536 20.8018 21.362 20.5762 21.2419 20.4238L17.2428 15.3262C17.2206 15.2981 17.1923 15.2754 17.1601 15.2599C17.1279 15.2443 17.0926 15.2362 17.0568 15.2362C17.021 15.2362 16.9857 15.2443 16.9535 15.2599C16.9212 15.2754 16.893 15.2981 16.8708 15.3262ZM11.2135 13.3604C11.2135 13.6712 11.337 13.9692 11.5568 14.189C11.7765 14.4088 12.0746 14.5322 12.3854 14.5322C12.6962 14.5322 12.9943 14.4088 13.214 14.189C13.4338 13.9692 13.5573 13.6712 13.5573 13.3604C13.5573 13.0496 13.4338 12.7515 13.214 12.5317C12.9943 12.3119 12.6962 12.1885 12.3854 12.1885C12.0746 12.1885 11.7765 12.3119 11.5568 12.5317C11.337 12.7515 11.2135 13.0496 11.2135 13.3604ZM25.7038 8.86621L19.3991 2.56152C19.2233 2.38574 18.986 2.28613 18.737 2.28613H6.29166C5.7731 2.28613 5.35416 2.70508 5.35416 3.22363V27.5986C5.35416 28.1172 5.7731 28.5361 6.29166 28.5361H25.0417C25.5602 28.5361 25.9792 28.1172 25.9792 27.5986V9.53125C25.9792 9.28223 25.8795 9.04199 25.7038 8.86621ZM23.817 9.96191H18.3034V4.44824L23.817 9.96191ZM23.8698 26.4268H7.46353V4.39551H16.3112V10.7236C16.3112 11.05 16.4408 11.3629 16.6716 11.5937C16.9023 11.8245 17.2153 11.9541 17.5417 11.9541H23.8698V26.4268Z"
      fill={fill}
    />
  </svg>
);

export default CardIcon2;
