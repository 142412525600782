import React from "react";

const WorkflowIcon3 = ({ fill }) => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8643 7.45703L15.6104 2.20313C15.4639 2.05664 15.2661 1.97363 15.0586 1.97363H4.6875C4.25537 1.97363 3.90625 2.32275 3.90625 2.75488V23.0674C3.90625 23.4995 4.25537 23.8486 4.6875 23.8486H20.3125C20.7446 23.8486 21.0938 23.4995 21.0938 23.0674V8.01123C21.0938 7.80371 21.0107 7.60352 20.8643 7.45703ZM19.292 8.37012H14.6973V3.77539L19.292 8.37012ZM19.3359 22.0908H5.66406V3.73145H13.0371V9.00488C13.0371 9.27683 13.1451 9.53765 13.3374 9.72994C13.5297 9.92224 13.7905 10.0303 14.0625 10.0303H19.3359V22.0908ZM13.2812 11.9346C13.2812 11.8271 13.1934 11.7393 13.0859 11.7393H11.9141C11.8066 11.7393 11.7188 11.8271 11.7188 11.9346V14.5713H9.08203C8.97461 14.5713 8.88672 14.6592 8.88672 14.7666V15.9385C8.88672 16.0459 8.97461 16.1338 9.08203 16.1338H11.7188V18.7705C11.7188 18.8779 11.8066 18.9658 11.9141 18.9658H13.0859C13.1934 18.9658 13.2812 18.8779 13.2812 18.7705V16.1338H15.918C16.0254 16.1338 16.1133 16.0459 16.1133 15.9385V14.7666C16.1133 14.6592 16.0254 14.5713 15.918 14.5713H13.2812V11.9346Z"
      fill={fill}
    />
  </svg>
);

export default WorkflowIcon3;
