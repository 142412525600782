import React from "react";

const ToolCard1 = ({ fill }) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0371 8.86646L18.7324 2.56177C18.5566 2.38599 18.3193 2.28638 18.0703 2.28638H5.625C5.10645 2.28638 4.6875 2.70532 4.6875 3.22388V27.5989C4.6875 28.1174 5.10645 28.5364 5.625 28.5364H24.375C24.8936 28.5364 25.3125 28.1174 25.3125 27.5989V9.53149C25.3125 9.28247 25.2129 9.04224 25.0371 8.86646ZM23.1504 9.96216H17.6367V4.44849L23.1504 9.96216ZM23.2031 26.427H6.79688V4.39575H15.6445V10.7239C15.6445 11.0502 15.7742 11.3632 16.0049 11.594C16.2357 11.8247 16.5487 11.9543 16.875 11.9543H23.2031V26.427ZM15.9375 14.2395C15.9375 14.1106 15.832 14.0051 15.7031 14.0051H14.2969C14.168 14.0051 14.0625 14.1106 14.0625 14.2395V17.4036H10.8984C10.7695 17.4036 10.6641 17.509 10.6641 17.6379V19.0442C10.6641 19.1731 10.7695 19.2786 10.8984 19.2786H14.0625V22.4426C14.0625 22.5715 14.168 22.677 14.2969 22.677H15.7031C15.832 22.677 15.9375 22.5715 15.9375 22.4426V19.2786H19.1016C19.2305 19.2786 19.3359 19.1731 19.3359 19.0442V17.6379C19.3359 17.509 19.2305 17.4036 19.1016 17.4036H15.9375V14.2395Z"
      fill={fill}
    />
  </svg>
);

export default ToolCard1;
