import React from "react";

const WorkflowIcon1 = ({ fill }) => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0249 2.21173C12.7251 -0.0880738 8.98 -0.0880738 6.68264 2.21173L0.31057 8.57892C0.269066 8.62042 0.247093 8.67657 0.247093 8.73517C0.247093 8.79376 0.269066 8.84991 0.31057 8.89142L1.21145 9.7923C1.25263 9.8333 1.30837 9.85631 1.36648 9.85631C1.42459 9.85631 1.48033 9.8333 1.52151 9.7923L7.89358 3.42511C8.68459 2.63409 9.73684 2.19952 10.855 2.19952C11.9732 2.19952 13.0254 2.63409 13.814 3.42511C14.605 4.21613 15.0396 5.26837 15.0396 6.38409C15.0396 7.50226 14.605 8.55206 13.814 9.34308L7.31985 15.8348L6.2676 16.887C5.28371 17.8709 3.68459 17.8709 2.70071 16.887C2.22463 16.4109 1.9634 15.7786 1.9634 15.1048C1.9634 14.431 2.22463 13.7986 2.70071 13.3226L9.14358 6.88214C9.30715 6.72101 9.52199 6.63068 9.75149 6.63068H9.75393C9.98342 6.63068 10.1958 6.72101 10.357 6.88214C10.5205 7.04571 10.6084 7.26056 10.6084 7.49005C10.6084 7.7171 10.5181 7.93195 10.357 8.09308L5.09084 13.3543C5.04934 13.3958 5.02737 13.452 5.02737 13.5106C5.02737 13.5692 5.04934 13.6253 5.09084 13.6668L5.99172 14.5677C6.0329 14.6087 6.08864 14.6317 6.14675 14.6317C6.20486 14.6317 6.2606 14.6087 6.30178 14.5677L11.5655 9.30402C12.0513 8.81818 12.3174 8.17365 12.3174 7.48761C12.3174 6.80157 12.0488 6.1546 11.5655 5.6712C10.562 4.66779 8.93117 4.67023 7.92776 5.6712L7.30276 6.29864L1.48733 12.1116C1.09263 12.504 0.779755 12.9709 0.566857 13.4851C0.353958 13.9993 0.245269 14.5507 0.247093 15.1072C0.247093 16.2376 0.688987 17.2996 1.48733 18.098C2.31496 18.9232 3.39895 19.3358 4.48293 19.3358C5.56692 19.3358 6.6509 18.9232 7.4761 18.098L15.0249 10.554C16.1358 9.44073 16.751 7.9588 16.751 6.38409C16.7534 4.80695 16.1382 3.32501 15.0249 2.21173Z"
      fill={fill}
    />
  </svg>
);

export default WorkflowIcon1;
