import React from "react";

const AnnotationTypeVideo = ({fill}) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8936 3.87009L16.1714 5.4375V2.20486C16.1714 1.45412 15.561 0.84375 14.8103 0.84375H2.22C1.46926 0.84375 0.858887 1.45412 0.858887 2.20486V14.4549C0.858887 15.2056 1.46926 15.816 2.22 15.816H14.8103C15.561 15.816 16.1714 15.2056 16.1714 14.4549V11.2222L18.8936 12.7896C19.3466 13.0512 19.9144 12.7237 19.9144 12.2026V4.4592C19.9144 3.93602 19.3466 3.6085 18.8936 3.87009ZM14.6401 14.2847H2.39014V2.375H14.6401V14.2847ZM18.3832 10.7331L16.1714 9.46128V7.20056L18.3832 5.92665V10.7331ZM3.92138 5.09722H6.30333C6.3969 5.09722 6.47347 5.02066 6.47347 4.92708V3.90625C6.47347 3.81267 6.3969 3.73611 6.30333 3.73611H3.92138C3.82781 3.73611 3.75125 3.81267 3.75125 3.90625V4.92708C3.75125 5.02066 3.82781 5.09722 3.92138 5.09722Z"
      fill={fill}
      className="dark:group-hover:fill-white dark:group-active:fill-white group-hover:fill-[#3F2193] group-active:fill-[#3F2193]"
    />
  </svg>
);

export default AnnotationTypeVideo;
