import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../../styles/page/new-pricing.scss";
import CustomButton from "../CustomButton";

const NextArrow = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.67251 9.66797L12.9575 9.66797L6.80161 15.0117C6.70318 15.0979 6.76294 15.2578 6.89302 15.2578L8.44868 15.2578C8.51724 15.2578 8.58228 15.2332 8.63325 15.1893L15.276 9.42539C15.3369 9.37265 15.3857 9.30745 15.4191 9.23421C15.4525 9.16097 15.4698 9.08139 15.4698 9.00088C15.4698 8.92036 15.4525 8.84079 15.4191 8.76755C15.3857 8.6943 15.3369 8.62911 15.276 8.57637L8.59458 2.77734C8.56821 2.75449 8.53657 2.74219 8.50318 2.74219L6.89478 2.74219C6.7647 2.74219 6.70493 2.90391 6.80337 2.98828L12.9575 8.33203L2.67251 8.33203C2.59517 8.33203 2.53189 8.39531 2.53189 8.47266L2.53189 9.52734C2.53189 9.60469 2.59517 9.66797 2.67251 9.66797Z"
      fill="#434343"
    />
  </svg>
);

const AnnotateCaseStudyCard = ({ data }) => {
  return (
    <div className="flex gap-2 overflow-x-auto sm:gap-6 scroll-smooth no-scrollbar tracking-tight_1">
      {data?.items?.map(({ icon, description1, button_text, link }, index) => (
        <div
          key={index}
          className="testimonial_card_section px-3.5 sm:px-7.5 pt-14 pb-9 min-w-[242px] sm:min-w-[368px] flex flex-col justify-between hover:border hover:border-purple-200 ease-in-out duration-500"
        >
          <div>
            {icon?.url && (
              <div className="w-[148px]">
                <img src={icon?.url} loading="lazy" alt="avatar" className="" />
              </div>
            )}

            <p className="sm:mt-7.5 mb-6 mt-5 max-w-[208px] sm:max-w-[308px] !text-lg tracking-tighter_3  sm:tracking-tighter_1 font-normal text-gray-2500  sm:h-56 leading-7 ">
              {description1.text}
            </p>
          </div>
          <div className="flex justify-start sm:justify-end">
            <CustomButton
              text={button_text.text}
              link={link.url}
              icon={<NextArrow />}
              buttonClass="bg-white hover:text-gray-2700 hover:bg-gray-2800 text-gray-2500"
              direction="right"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnnotateCaseStudyCard;
