import React from "react";

const Dicom = ({fill}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_463_7400)">
      <path
        d="M19.7237 8.71397H17.8072C17.2822 5.32942 14.6013 2.64852 11.2168 2.12352V0.207031H8.78624V2.12352C5.40169 2.64852 2.72079 5.32942 2.19579 8.71397H0.279297V11.1445H2.19579C2.72079 14.5291 5.40169 17.21 8.78624 17.735V19.6515H11.2168V17.735C14.6013 17.21 17.2822 14.5291 17.8072 11.1445H19.7237V8.71397ZM15.3342 8.71397H13.4395C13.0737 7.67855 12.2522 6.85702 11.2168 6.49123V4.59661C13.2585 5.06206 14.8687 6.6723 15.3342 8.71397ZM10.0015 11.1445C9.33069 11.1445 8.78624 10.6001 8.78624 9.92924C8.78624 9.25841 9.33069 8.71397 10.0015 8.71397C10.6724 8.71397 11.2168 9.25841 11.2168 9.92924C11.2168 10.6001 10.6724 11.1445 10.0015 11.1445ZM8.78624 4.59661V6.49123C7.75082 6.85702 6.9293 7.67855 6.5635 8.71397H4.66888C5.13433 6.6723 6.74458 5.06206 8.78624 4.59661ZM4.66888 11.1445H6.5635C6.9293 12.1799 7.75082 13.0015 8.78624 13.3673V15.2619C6.74458 14.7964 5.13433 13.1862 4.66888 11.1445ZM11.2168 15.2619V13.3673C12.2522 13.0015 13.0737 12.1799 13.4395 11.1445H15.3342C14.8687 13.1862 13.2585 14.7964 11.2168 15.2619Z"
        fill={fill}
        className="dark:group-hover:fill-white dark:group-active:fill-white group-hover:fill-[#3F2193]  group-active:fill-[#3F2193]"
      />
    </g>
    <defs>
      <clipPath id="clip0_463_7400">
        <rect
          width="19.4444"
          height="19.4444"
          fill="white"
          transform="translate(0.27832 0.207031)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Dicom;
