import React from "react";

const CardIcon1 = ({ fill }) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1562 9.55176V8.14551C20.1562 8.0166 20.0508 7.91113 19.9219 7.91113H8.67187C8.54297 7.91113 8.4375 8.0166 8.4375 8.14551V9.55176C8.4375 9.68066 8.54297 9.78613 8.67187 9.78613H19.9219C20.0508 9.78613 20.1562 9.68066 20.1562 9.55176ZM8.67187 12.1299C8.54297 12.1299 8.4375 12.2354 8.4375 12.3643V13.7705C8.4375 13.8994 8.54297 14.0049 8.67187 14.0049H14.0625C14.1914 14.0049 14.2969 13.8994 14.2969 13.7705V12.3643C14.2969 12.2354 14.1914 12.1299 14.0625 12.1299H8.67187ZM12.8906 25.3721H6.09375V4.74707H22.5V14.8252C22.5 14.9541 22.6055 15.0596 22.7344 15.0596H24.375C24.5039 15.0596 24.6094 14.9541 24.6094 14.8252V3.5752C24.6094 3.05664 24.1904 2.6377 23.6719 2.6377H4.92187C4.40332 2.6377 3.98438 3.05664 3.98438 3.5752V26.5439C3.98438 27.0625 4.40332 27.4814 4.92187 27.4814H12.8906C13.0195 27.4814 13.125 27.376 13.125 27.2471V25.6064C13.125 25.4775 13.0195 25.3721 12.8906 25.3721ZM25.9482 26.8809L23.2148 24.1475C23.8682 23.2832 24.2578 22.2051 24.2578 21.0361C24.2578 18.1885 21.9492 15.8799 19.1016 15.8799C16.2539 15.8799 13.9453 18.1885 13.9453 21.0361C13.9453 23.8838 16.2539 26.1924 19.1016 26.1924C20.1504 26.1924 21.123 25.8789 21.9375 25.3428L24.7119 28.1172C24.7588 28.1641 24.8174 28.1846 24.876 28.1846C24.9346 28.1846 24.9961 28.1611 25.04 28.1172L25.9482 27.209C25.9699 27.1875 25.987 27.1619 25.9987 27.1338C26.0104 27.1056 26.0164 27.0754 26.0164 27.0449C26.0164 27.0144 26.0104 26.9842 25.9987 26.9561C25.987 26.9279 25.9699 26.9024 25.9482 26.8809ZM19.1016 24.3174C17.2881 24.3174 15.8203 22.8496 15.8203 21.0361C15.8203 19.2227 17.2881 17.7549 19.1016 17.7549C20.915 17.7549 22.3828 19.2227 22.3828 21.0361C22.3828 22.8496 20.915 24.3174 19.1016 24.3174Z"
      fill={fill}
    />
  </svg>
);

export default CardIcon1;
