import React from "react";

const EcgAnnotation = ({fill}) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 0.183594H1.94444C0.875 0.183594 0 1.05859 0 2.12804V5.0447H1.94444V2.12804H17.5V5.0447H19.4444V2.12804C19.4444 1.05859 18.5694 0.183594 17.5 0.183594ZM17.5 13.7947H1.94444V10.878H0V13.7947C0 14.8641 0.875 15.7391 1.94444 15.7391H17.5C18.5694 15.7391 19.4444 14.8641 19.4444 13.7947V10.878H17.5V13.7947Z"
      fill={fill}
      className="dark:group-hover:fill-white dark:group-active:fill-white group-hover:fill-[#3F2193] group-active:fill-[#3F2193]"
    />
    <path
      d="M12.5319 3.63255C12.2014 2.97144 11.1222 2.97144 10.7917 3.63255L7.77778 9.67004L6.69861 7.52144C6.61889 7.36025 6.49553 7.22467 6.34256 7.13014C6.18959 7.03561 6.01315 6.98592 5.83333 6.98671H0V8.93116H5.23056L6.90278 12.2853C7.07778 12.6159 7.40833 12.82 7.77778 12.82C8.14722 12.82 8.47778 12.6159 8.64306 12.2853L11.6667 6.24783L12.7458 8.39643C12.9111 8.72699 13.2417 8.93116 13.6111 8.93116H19.4444V6.98671H14.2139L12.5319 3.63255Z"
      fill={fill}
      className="dark:group-hover:fill-white dark:group-active:fill-white group-hover:fill-[#3F2193] group-active:fill-[#3F2193]"
    />
  </svg>
);

export default EcgAnnotation;
