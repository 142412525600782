import * as React from "react";
import VideoComponent from "../VideoComponent";

const Video = ({ src, posterImage, classes }) => {
    return (
      <VideoComponent
        poster={posterImage}
        data-aos="fade-up"
        data-aos-delay="10"
        key={src}
        className={classes}
      >
        <source src={src} type="video/mp4" />
      </VideoComponent>
    );
  };

  export default Video;