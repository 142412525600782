import React, { useState } from "react";
import VideoComponent from "../VideoComponent";
import CardIcon1 from "../../assets/icons/NewAnnotate/GridCardIcons/CardIcon1";
import CardIcon2 from "../../assets/icons/NewAnnotate/GridCardIcons/CardIcon2";
import CardIcon3 from "../../assets/icons/NewAnnotate/GridCardIcons/CardIcon3";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CardFlex = ({ data }) => {
  const iconItems = data?.items?.slice(-3);
  const labelItems = data?.items;
  const [hoverIndex, setHoverIndex] = useState(null);

  const GridCardIcons = [
    {
      icon: <CardIcon1 />,
    },
    {
      icon: <CardIcon2 />,
    },
    {
      icon: <CardIcon3 />,
    },
  ];

  const itemStyle =
    "bg-gray-2800 border border-gray-1600 rounded-xl sm:rounded-2xl flex- flex-col";

  const lablePadding = "sm:px-5 px-4 py-6";

  const lableStyle =
    "uppercase tracking-widest_1 group-hover:text-purple-1500 font-medium text-gray-600 lg:mb-2.5 mb-2 sm:text-base text-xs";

  const headingStyle =
    "font-manrope sm:text-2.5xl text-2xl !tracking-tighter_3 text-gray-2700 font-semibold sm:mb-3 mb-2";

  const descriptionStyle =
    "sm:text-base text-sm text-gray-600 !tracking-tighter_3";

  return (
    <div className="bg-white sm:py-12 p-6 px-4 sm:px-6 lg:px-10 rounded-2xl sm:rounded-2.5xl">
      <div className="flex gap-2.5  lg:flex-row flex-col">
        <div className={`${itemStyle} ${lablePadding} lg:max-w-[467px] group`}>
          <div className={lableStyle}>{labelItems[0]?.card_label?.text}</div>
          <div className={headingStyle}>
            {labelItems[0]?.card_heading?.text}
          </div>
          <div className={descriptionStyle}>
            {labelItems[0]?.card_description?.text}
          </div>
          {labelItems[0]?.card_video ? (
            <VideoComponent
              src={labelItems[0]?.card_video?.url}
              className="w-auto h-full mt-5"
            />
          ) : (
            <GatsbyImage
              image={getImage(labelItems[0]?.card_image)}
              width={labelItems[0]?.card_image?.dimensions?.width || 0}
              height={labelItems[0]?.card_image?.dimensions?.height || 0}
              alt={labelItems[0]?.card_label?.text || "card_image"}
              className="w-full h-auto mt-5"
              loading="lazy"
            />
          )}
        </div>
        <div className="flex flex-col gap-2.5 ">
          <div className={`${lablePadding} ${itemStyle} group`}>
            {labelItems[1]?.card_video ? (
              <VideoComponent
                src={labelItems[1]?.card_video?.url}
                className="w-full h-auto mx-auto mt-2.5 mb-5"
              />
            ) : (
              <GatsbyImage
              image={getImage(labelItems[1]?.card_image)}
                width={labelItems[1]?.card_image?.dimensions?.width || 0}
                height={labelItems[1]?.card_image?.dimensions?.height || 0}
                alt={labelItems[1]?.card_label?.text || "card_image"}
                loading="lazy"
                className="mx-auto w-full mt-2.5 mb-5"
              />
            )}
            <div className={lableStyle}>{labelItems[1]?.card_label?.text}</div>
            <div className={headingStyle}>
              {labelItems[1]?.card_heading?.text}
            </div>
            <div className={descriptionStyle}>
              {labelItems[1]?.card_description?.text}
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-2.5 w-full">
            <div className={`sm:w-1/2 ${itemStyle} ${lablePadding} group`}>
              {labelItems[2]?.card_video ? (
                <VideoComponent
                  src={labelItems[2]?.card_video?.url}
                  className="w-full h-auto mb-5"
                />
              ) : (
                <GatsbyImage
              image={getImage(labelItems[2]?.card_image)}
                  width={labelItems[2]?.card_image?.dimensions?.width || 0}
                  height={labelItems[2]?.card_image?.dimensions?.height || 0}
                  alt={labelItems[2]?.card_label?.text || "card_image"}
                  loading="lazy"
                  className="w-full h-auto mb-5"
                />
              )}
              <div className={lableStyle}>
                {labelItems[2]?.card_label?.text}
              </div>
              <div className={headingStyle}>
                {labelItems[2]?.card_heading?.text}
              </div>
              <div className={descriptionStyle}>
                {labelItems[2]?.card_description?.text}
              </div>
            </div>
            <div className={`sm:w-1/2 ${itemStyle} ${lablePadding} group`}>
              {labelItems[3]?.card_video ? (
                <VideoComponent
                  src={labelItems[3]?.card_video?.url}
                  className="w-full h-auto"
                />
              ) : (
                <GatsbyImage
              image={getImage(labelItems[3]?.card_image)}
                  width={labelItems[3]?.card_image?.dimensions?.width || 0}
                  height={labelItems[3]?.card_image?.dimensions?.height || 0}
                  alt={labelItems[3]?.card_label?.text || "card_image"}
                  loading="lazy"
                  className="w-full h-auto mb-5"
                />
              )}
              <div className={lableStyle}>
                {labelItems[3]?.card_label?.text}
              </div>
              <div className={headingStyle}>
                {labelItems[3]?.card_heading?.text}
              </div>
              <div className={descriptionStyle}>
                {labelItems[3]?.card_description?.text}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-3 sm:grid-row-1 grid-cols-1 gap-2.5 mt-2.5 tracking-tighter_3">
        {iconItems?.map((item, index) => (
          <div
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
            className={`${itemStyle} sm:px-5 px-4 sm:py-7 py-6`}
          >
            <div className={`${hoverIndex !== index}`}>
              {React.cloneElement(GridCardIcons[index].icon, {
                fill: hoverIndex === index ? "#5E44FF" : "#595959",
              })}
            </div>
            <div className="font-manrope text-xl font-semibold text-gray-2700 mt-3.5 mb-2.5 tracking-tighter_3">
              {item?.card_heading?.text}
            </div>
            <div className={descriptionStyle}>
              {item?.card_description?.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardFlex;
