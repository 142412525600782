import React from "react";

const ToolIcon7 = ({ fill }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 8.08862H31C31.275 8.08862 31.5 8.31362 31.5 8.58862V18.0886C31.5 18.3636 31.275 18.5886 31 18.5886H21.5C21.225 18.5886 21 18.3636 21 18.0886V8.58862C21 8.31362 21.225 8.08862 21.5 8.08862ZM23.125 16.4636H29.375V10.2136H23.125V16.4636ZM9 8.08862H18.5C18.775 8.08862 19 8.31362 19 8.58862V18.0886C19 18.3636 18.775 18.5886 18.5 18.5886H9C8.725 18.5886 8.5 18.3636 8.5 18.0886V8.58862C8.5 8.31362 8.725 8.08862 9 8.08862ZM10.625 16.4636H16.875V10.2136H10.625V16.4636ZM9 20.5886H18.5C18.775 20.5886 19 20.8136 19 21.0886V30.5886C19 30.8636 18.775 31.0886 18.5 31.0886H9C8.725 31.0886 8.5 30.8636 8.5 30.5886V21.0886C8.5 20.8136 8.725 20.5886 9 20.5886ZM10.625 28.9636H16.875V22.7136H10.625V28.9636ZM22.75 25.8386H26.75V21.8386C26.75 21.7011 26.8625 21.5886 27 21.5886H28.5C28.6375 21.5886 28.75 21.7011 28.75 21.8386V25.8386H32.75C32.8875 25.8386 33 25.9511 33 26.0886V27.5886C33 27.7261 32.8875 27.8386 32.75 27.8386H28.75V31.8386C28.75 31.9761 28.6375 32.0886 28.5 32.0886H27C26.8625 32.0886 26.75 31.9761 26.75 31.8386V27.8386H22.75C22.6125 27.8386 22.5 27.7261 22.5 27.5886V26.0886C22.5 25.9511 22.6125 25.8386 22.75 25.8386Z"
      fill={fill}
    />
  </svg>
);

export default ToolIcon7;
