import React, { useState } from "react";
import VideoComponent from "../VideoComponent";
import ToolCard1 from "../../assets/icons/NewAnnotate/AnnotationToolCard/ToolCard1";
import ToolCard2 from "../../assets/icons/NewAnnotate/AnnotationToolCard/ToolCard2";
import ToolCard3 from "../../assets/icons/NewAnnotate/AnnotationToolCard/ToolCard3";
import { useMediaQuery } from "react-responsive";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AnnotationAccelrate = ({ data }) => {
  const [hoverIndex, setHoverIndex] = useState(null);

  const [mainCard, ...otherCards] = data?.items || [];
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const ToolCardIcons = [
    {
      icon: <ToolCard1 />,
    },
    {
      icon: <ToolCard2 />,
    },
    {
      icon: <ToolCard3 />,
    },
  ];
  return (
    <div className="flex flex-col justify-between w-full gap-5 lg:flex-row">
      {/*  main card */}
      <div className="flex flex-col justify-between lg:w-[614px]">
        {mainCard?.card_video ? (
          <div className="flex items-center justify-center border bg-gray-2800 group lg:h-96 border-gray-1600 rounded-xl sm:rounded-2xl">
            <VideoComponent
              src={mainCard?.card_video?.url}
              className="w-full h-auto "
            />
          </div>
        ) : (
          mainCard?.card_image?.url && (
            <GatsbyImage
              image={getImage(mainCard?.card_image)}
              height={416}
              width={614}
              alt={mainCard?.card_image?.alt || "main card image"}
              className="w-full"
              loading="lazy"
            />
          )
        )}
        <div>
          <div className="font-manrope sm:text-2.5xl text-2xl tracking-tighter_3 text-purple-1100 font-semibold sm:mb-3 mt-8 mb-4">
            {mainCard?.card_heading?.text}
          </div>
          <div className="text-sm text-gray-600 sm:text-base tracking-tighter_3">
            {mainCard?.card_description?.text}
          </div>
        </div>
      </div>

      <div className="lg:w-[448px] flex flex-col gap-6 lg:px-5">
        {otherCards?.map((item, index) => (
          <div
            className={`bg-gray-2800 group rotating-border border border-gray-1600 rounded-xl sm:rounded-2xl flex flex-col sm:px-5 px-4 sm:py-7 py-6`}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            <div className={`${hoverIndex !== index}`}>
              {React.cloneElement(ToolCardIcons[index].icon, {
                fill:
                  hoverIndex === index || !isDesktop ? "#5E44FF" : "#595959",
              })}
            </div>

            <div className="font-manrope text-xl font-semibold text-purple-1100 lg:text-gray-600  lg:group-hover:text-purple-1100 mt-3.5 mb-2.5 tracking-tighter_3">
              {item?.card_heading?.text}
            </div>
            <div className="text-sm sm:text-base text-gray-2500 lg:text-gray-1200 lg:group-hover:text-gray-2500 tracking-tighter_3">
              {item?.card_description?.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnnotationAccelrate;
