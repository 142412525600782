import React, { useState } from "react";
import ToolIcon1 from "../../assets/icons/NewAnnotate/ToolIcon1";
import ToolIcon3 from "../../assets/icons/NewAnnotate/ToolIcon3";
import ToolIcon4 from "../../assets/icons/NewAnnotate/ToolIcon4";
import ToolIcon5 from "../../assets/icons/NewAnnotate/ToolIcon5";
import ToolIcon6 from "../../assets/icons/NewAnnotate/ToolIcon6";
import ToolIcon7 from "../../assets/icons/NewAnnotate/ToolIcon7";
import CustomButton from "../CustomButton";
import ToolIcon2 from "../../assets/icons/NewAnnotate/ToolIcon2";
import ToolIcon8 from "../../assets/icons/NewAnnotate/ToolIcon8";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AnnotationToolsSection = ({ data, openModal }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const AnnotationToolIcons = [
    // {
    //   icon: <ToolIcon1 fill={activeIndex === 0 ? "#5E44FF" : "#595959"} />,
    // },
    {
      icon: <ToolIcon2 fill={activeIndex === 0 ? "#5E44FF" : "#595959"} />,
    },
    {
      icon: <ToolIcon3 fill={activeIndex === 1 ? "#5E44FF" : "#595959"} />,
    },
    {
      icon: <ToolIcon4 fill={activeIndex === 2 ? "#5E44FF" : "#595959"} />,
    },
    {
      icon: <ToolIcon5 fill={activeIndex === 3 ? "#5E44FF" : "#595959"} />,
    },
    {
      icon: <ToolIcon6 fill={activeIndex === 4 ? "#5E44FF" : "#595959"} />,
    },
    {
      icon: <ToolIcon7 fill={activeIndex === 5 ? "#5E44FF" : "#595959"} />,
    },
    {
      icon: <ToolIcon8 fill={activeIndex === 6 ? "#5E44FF" : "#595959"} />,
    },
  ];

  const handleIconClick = (index) => {
    setHoveredIndex(index);
    setActiveIndex(index);
  };

  return (
    <div className="flex flex-col gap-10 px-4">
      <div className="flex flex-col justify-center gap-5 text-center ">
        <p className="text-xs font-medium uppercase sm:text-base text-purple-1500 tracking-widest_1">
          {data?.primary?.section_label?.text}
        </p>
        <h1 className="text-2xl sm:text-3.25xl font-semibold leading-10 font-manrope tracking-[-1.8px] ">
          {data?.primary?.section_heading?.text}
        </h1>
        <p className="text-sm text-center text-gray-2500 sm:text-base sm:max-w-[500px] lg:max-w-[804px] mx-auto tracking-tighter_3">
          {data?.primary?.section_description?.text}
        </p>
        <div className="flex flex-row mx-auto gap-3.5">
          <CustomButton
            text={data?.primary?.cta_text_1?.text}
            buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] text-base`}
            onClick={openModal}
          />
          <CustomButton
            text={data?.primary?.cta_text_2?.text}
            link={data?.primary?.cta_link_2?.url}
            buttonClass={` bg-gray-2800 hover:bg-gray-2900 text-gray-2500 text-base border border-gray-2200`}
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-6 mx-auto md:gap-3 ">
        <div className="min-w-full sm:!max-w-fit rounded-[5px] bg-gray-1500 p-1.5">
          <div className="grid !items-center grid-flow-row grid-cols-4 sm:gap-3.5 gap-y-1.5 !justify-items-center sm:grid-cols-7">
            {AnnotationToolIcons.map((item, index) => (
              <div
                key={index}
                onClick={() => handleIconClick(index)}
                className={`cursor-pointer rounded-[4px]  p-[5px] bg-red flex justify-center items-center w-min  lg:hover:bg-[#FFFFFF] ${
                  index === activeIndex ? "active bg-[#FFFFFF]" : ""
                }`}
              >
                {item.icon}
              </div>
            ))}
          </div>
        </div>

        {data?.items?.map((value, index) => (
          <div
            key={index}
            style={{
              display:
                index === hoveredIndex || (hoveredIndex === null && index === 0)
                  ? "block"
                  : "none",
            }}
          >
            {value?.slide_image?.url && (
              <GatsbyImage
                image={getImage(value?.slide_image)}
                loading="lazy"
                alt={`Slide ${index + 1}`}
                className="rounded-xl sm:rounded-2xl lg:max-w-[545px]"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnnotationToolsSection;
