import * as React from "react";
import VideoComponent from "../VideoComponent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const HorizontalCard = ({ data }) => {
  const [item] = data?.items || [];
  return (
    <section
      className={`mx-auto flex justify-center lg:items-center bg-white rounded-2.5xl border border-gray-1600 overflow-hidden lg:flex-row flex-col xl:gap-x-10 lg:min-h-[416px]`}
    >
      <div className="flex lg:justify-center rounded-3xl">
        {item?.section_video?.url ? (
          <VideoComponent
            loop
            autoPlay
            muted
            className="w-full h-auto mx-auto rounded-2xl"
          >
            <source src={videoSrc} type="video/mp4" />
          </VideoComponent>
        ) : (
          <GatsbyImage
            image={getImage(item?.section_image)}
            alt={item?.section_image?.alt || "section_image"}
            className="w-auto lg:max-w-[564px] horizontal_margin-gatsby"
            loading="lazy"
          />
        )}
      </div>
      <div
        className={`flex flex-col gap-4 pt-6 pb-6 sm:pb-8 sm:pt-6 lg:py-10 px-4 sm:px-5 lg:max-w-[578px]`}
      >
        {data?.primary?.label?.text && (
          <div className="max-w-[50px] uppercase sm:text-xs text-2xs tracking-widest_1 font-semibold text-[#3F2193]  px-2.5 py-1 bg-[#F9F0FF] font-manrope rounded-full">
            {data?.primary?.label?.text}
          </div>
        )}

        <h4 className="text-purple-1100 text-2xl font-manrope sm:text-2.5xl font-semibold leading-tight !tracking-tighter_3">
          {data?.primary?.section_heading?.text}
        </h4>
        <p className="text-sm sm:text-base text-gray-2500 tracking-tighter_1 sm:mr-22 lg:mr-10 font-inter">
          {data?.primary?.section_description?.text}
        </p>
      </div>
    </section>
  );
};

export default HorizontalCard;
