import React from "react";

const WorkflowIcon2 = ({ fill }) => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6396 7.9043H19.9165C20.1094 7.9043 20.2656 7.74805 20.2656 7.55518V1.27588C20.2656 1.08301 20.1094 0.926758 19.9165 0.926758H13.6396C13.4468 0.926758 13.2905 1.08301 13.2905 1.27588V3.54395H9.97754C9.88232 3.54395 9.8042 3.62207 9.8042 3.71728V9.125H7.71191V6.76904C7.71191 6.57617 7.55566 6.41992 7.36279 6.41992H1.0835C0.890625 6.41992 0.734375 6.57617 0.734375 6.76904V13.0483C0.734375 13.2412 0.890625 13.3975 1.0835 13.3975H7.36035C7.55322 13.3975 7.70947 13.2412 7.70947 13.0483V10.6924H9.80176V16.1001C9.80176 16.1953 9.87988 16.2734 9.9751 16.2734H13.2881V18.5415C13.2881 18.7344 13.4443 18.8906 13.6372 18.8906H19.9141C20.1069 18.8906 20.2632 18.7344 20.2632 18.5415V12.2671C20.2632 12.0742 20.1069 11.918 19.9141 11.918H13.6396C13.4468 11.918 13.2905 12.0742 13.2905 12.2671V14.7085H11.3716V5.11377H13.2905V7.55518C13.2905 7.74805 13.4468 7.9043 13.6396 7.9043ZM14.9458 2.58447H18.6079V6.24902H14.9458V2.58447ZM6.0542 11.7422H2.39209V8.08008H6.0542V11.7422ZM14.9458 13.5757H18.6079V17.2402H14.9458V13.5757Z"
      fill={fill}
    />
  </svg>
);

export default WorkflowIcon2;
