import React from "react";

const ToolIcon8 = ({ fill }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5417 9.86511C24.7419 9.86511 23.2799 11.3271 23.2799 13.1268C23.2799 14.5859 24.2439 15.8236 25.5661 16.2401V19.0329L14.3102 22.7199V14.4403C15.5974 14.0034 16.5235 12.7832 16.5235 11.3504C16.5235 9.55058 15.0615 8.08862 13.2617 8.08862C11.462 8.08862 10 9.55058 10 11.3504C10 12.7832 10.9261 14.0005 12.2133 14.4403V25.737C10.9261 26.1738 10 27.394 10 28.8269C10 30.6267 11.462 32.0886 13.2617 32.0886C15.0615 32.0886 16.5235 30.6267 16.5235 28.8269C16.5235 27.394 15.5974 26.1767 14.3102 25.737V24.9274L26.6436 20.888C26.9398 20.7918 27.1978 20.604 27.3804 20.3517C27.5631 20.0995 27.661 19.7958 27.66 19.4843V16.1906C28.9094 15.7304 29.8034 14.5306 29.8034 13.1268C29.8034 11.3271 28.3415 9.86511 26.5417 9.86511ZM11.8639 11.3504C11.8713 10.9846 12.0219 10.6363 12.2832 10.3802C12.5446 10.1241 12.8959 9.98072 13.2617 9.98072C13.6276 9.98072 13.9789 10.1241 14.2403 10.3802C14.5016 10.6363 14.6522 10.9846 14.6596 11.3504C14.6522 11.7162 14.5016 12.0645 14.2403 12.3205C13.9789 12.5766 13.6276 12.72 13.2617 12.72C12.8959 12.72 12.5446 12.5766 12.2832 12.3205C12.0219 12.0645 11.8713 11.7162 11.8639 11.3504ZM14.6596 28.824C14.6522 29.1898 14.5016 29.5381 14.2403 29.7941C13.9789 30.0502 13.6276 30.1936 13.2617 30.1936C12.8959 30.1936 12.5446 30.0502 12.2832 29.7941C12.0219 29.5381 11.8713 29.1898 11.8639 28.824C11.8713 28.4582 12.0219 28.1099 12.2832 27.8538C12.5446 27.5977 12.8959 27.4543 13.2617 27.4543C13.6276 27.4543 13.9789 27.5977 14.2403 27.8538C14.5016 28.1099 14.6522 28.4582 14.6596 28.824ZM26.5417 14.5247C26.1759 14.5173 25.8276 14.3667 25.5715 14.1054C25.3155 13.844 25.172 13.4927 25.172 13.1268C25.172 12.761 25.3155 12.4097 25.5715 12.1483C25.8276 11.887 26.1759 11.7364 26.5417 11.729C26.9075 11.7364 27.2558 11.887 27.5119 12.1483C27.7679 12.4097 27.9113 12.761 27.9113 13.1268C27.9113 13.4927 27.7679 13.844 27.5119 14.1054C27.2558 14.3667 26.9075 14.5173 26.5417 14.5247Z"
      fill={fill}
    />
  </svg>
);

export default ToolIcon8;
