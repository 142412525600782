import React, { useEffect, useState } from "react";

import { G2Logo } from "../../assets/icons/G2Logo";
import G2ReviewsIcon from "../../assets/icons/G2Reviews";
import { Link } from "gatsby-link";

const AnnotateG2Review = ({
  data,
  main_Head_class,
  heading_class,
  sub_heading_class,
  CardClass,
  LogoDivClass,
  index,
}) => {
  const [smallScreen, SetIsSmallScreen] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      SetIsSmallScreen(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div
      className={`flex flex-col justify-between  ${CardClass} md:max-w-[288px] lg:max-w-[524px] rounded-xl sm:rounded-2xl  bg-gray-1500 dark:bg-[#ffffff0d]  border dark:lg:hover:bg-purple-1100  dark:backdrop-blur-sm dark:border-[#FAFAFA33] border-gray-200 `}
    >
      <div className="flex flex-col">
        {data?.label?.text && (
          <p className={`text-[#FF6240] dark:text-red-100 ${main_Head_class}`}>
            {data?.label?.text}
          </p>
        )}

        <h1
          className={`text-xl lg:text-2xl font-semibold font-manrope text-gray-2500 dark:text-gray-2800 ${heading_class}`}
        >
          {data?.review_heading?.text}
        </h1>
        <p
          className={`font-normal font-inter min-h-[168px] text-gray-1200 dark:text-gray-300  ${sub_heading_class}`}
        >
          {data?.review_description?.text}
        </p>
      </div>
      <div
        className={`flex flex-col md:flex-row gap-3 md:items-center justify-between ${LogoDivClass}`}
      >
        <div className="text-base font-medium leading-6 text-gray-600 dark:text-gray-2800 font-inter">
          {data?.author_name?.text && <p>{data?.author_name?.text}</p>}
        </div>
        <div>
          {index === 0 ? (
            <Link
              target="blank"
              to="https://www.g2.com/products/encord/reviews"
            >
              <div className="flex justify-end">
                <G2Logo />
              </div>
            </Link>
          ) : (
            <G2ReviewsIcon />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnotateG2Review;
