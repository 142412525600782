import React from "react";

const ToolIcon6 = ({ fill }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2416 17.4107H32.6276C32.8853 17.4107 33.094 17.202 33.094 16.9443V8.55505C33.094 8.29738 32.8853 8.08862 32.6276 8.08862H24.2416C23.984 8.08862 23.7752 8.29738 23.7752 8.55505V11.5852H19.349C19.2218 11.5852 19.1174 11.6896 19.1174 11.8168V19.0416H16.3221V15.894C16.3221 15.6363 16.1133 15.4276 15.8557 15.4276H7.46643C7.20875 15.4276 7 15.6363 7 15.894V24.2832C7 24.5409 7.20875 24.7497 7.46643 24.7497H15.8524C16.1101 24.7497 16.3188 24.5409 16.3188 24.2832V21.1356H19.1142V28.3604C19.1142 28.4876 19.2185 28.592 19.3457 28.592H23.7719V31.6222C23.7719 31.8799 23.9807 32.0886 24.2384 32.0886H32.6244C32.882 32.0886 33.0908 31.8799 33.0908 31.6222V23.2395C33.0908 22.9818 32.882 22.773 32.6244 22.773H24.2416C23.984 22.773 23.7752 22.9818 23.7752 23.2395V26.5012H21.2115V13.6825H23.7752V16.9443C23.7752 17.202 23.984 17.4107 24.2416 17.4107ZM25.9867 10.3034H30.8793V15.1993H25.9867V10.3034ZM14.1074 22.5382H9.21473V17.6456H14.1074V22.5382ZM25.9867 24.9878H30.8793V29.8837H25.9867V24.9878Z"
      fill={fill}
    />
  </svg>
);

export default ToolIcon6;
