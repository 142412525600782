import React from "react";
import CustomButton from "../CustomButton";
import { Chevron } from "../../assets/icons";

const AnnotateCTACard = ({ data }) => {
  return (
    <div className="relative flex flex-col gap-5 px-5 py-10 overflow-hidden text-white sm:p-10 sm:gap-7 lg:gap-10 lg:flex-row rounded-2xl sm:rounded-2.5xl Cta_background">
      <div className="absolute top-0 left-0 hidden md:flex">
        <svg
          width="328"
          height="94"
          viewBox="0 0 328 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.1"
            width="387.238"
            height="127"
            rx="63.5"
            transform="matrix(-0.766044 -0.642788 -0.642788 0.766044 353 22.4597)"
            fill="#C5BBFF"
            fill-opacity="0.5"
          />
        </svg>
      </div>
      <div className="absolute top-0 left-0 hidden md:flex">
        <svg
          width="206"
          height="177"
          viewBox="0 0 206 177"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.1"
            width="333"
            height="127"
            rx="63.5"
            transform="matrix(-0.766044 -0.642788 -0.642788 0.766044 231 105.459)"
            fill="#C5BBFF"
            fill-opacity="0.5"
          />
        </svg>
      </div>
      <div className="absolute bottom-0 left-0 hidden md:flex">
        <svg
          width="202"
          height="238"
          viewBox="0 0 202 238"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.1"
            width="333"
            height="127"
            rx="63.5"
            transform="matrix(-0.766044 -0.642788 -0.642788 0.766044 227 188.459)"
            fill="#C5BBFF"
            fill-opacity="0.5"
          />
        </svg>
      </div>
      <div className="lg:min-w-[423px]">
        <h1 className="font-bold text-4.5xl ctaCardHeading sm:text-7xl font-manrope tracking-tight_1">
          {data?.section_heading?.text}
        </h1>
        <p className="font-manrope font-bold  text-xl max-w-[243px] sm:max-w-[423px] sm:text-2.5xl tracking-[-1px] sm:tracking-[-1.5px]">
          {data?.section_sub_heading?.text}
        </p>
      </div>
      <div className="flex flex-col gap-7">
        <div>
          <p className="text-base font-normal sm:text-xl text-gray-1600 tracking-tightest">
            {data?.section_description?.text}
          </p>
        </div>
        <div>
          <p className="text-base font-semibold sm:text-lg font-manrope tracking-tighter_3">
            {data?.reviewer_name?.text}
          </p>
          <p className=" text-sm sm:text-lg tracking-[-0.7px] sm:tracking-tighter_3 text-gray-2200 ">
            {data?.reviewer_role?.text}
          </p>
        </div>
        <CustomButton
          direction={`right`}
          icon={<Chevron />}
          text={data?.cta_text?.text}
          link={data?.cta_link?.url}
          buttonClass="bg-gray-1600 hover:bg-gray-2900 text-gray-2500"
        />
      </div>
    </div>
  );
};

export default AnnotateCTACard;
