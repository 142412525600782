import React from "react";

const ToolIcon5 = ({ fill }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.2 8.08887V10.5717C23.3118 10.8409 25.2744 11.8038 26.7798 13.3091C28.2851 14.8145 29.248 16.7771 29.5172 18.8889H32V21.2889H29.5172C29.2482 23.4007 28.2854 25.3635 26.78 26.8689C25.2746 28.3742 23.3119 29.337 21.2 29.6061V32.0889H18.8V29.6061C16.6881 29.337 14.7254 28.3742 13.22 26.8689C11.7146 25.3635 10.7518 23.4007 10.4828 21.2889H8V18.8889H10.4828C10.752 16.7771 11.7149 14.8145 13.2202 13.3091C14.7256 11.8038 16.6882 10.8409 18.8 10.5717V8.08887H21.2ZM12.8 20.0889C12.8 24.0597 16.0292 27.2889 20 27.2889C23.9708 27.2889 27.2 24.0597 27.2 20.0889C27.2 16.1181 23.9708 12.8889 20 12.8889C16.0292 12.8889 12.8 16.1181 12.8 20.0889ZM23.6004 20.0888C23.6004 22.077 21.9886 23.6888 20.0004 23.6888C18.0122 23.6888 16.4004 22.077 16.4004 20.0888C16.4004 18.1005 18.0122 16.4888 20.0004 16.4888C21.9886 16.4888 23.6004 18.1005 23.6004 20.0888Z"
      fill={fill}
    />
  </svg>
);

export default ToolIcon5;
