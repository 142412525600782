import React from "react";

const DocumentsIcon = ({fill}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0297 7.47109L14.9859 2.42734C14.8453 2.28672 14.6555 2.20703 14.4562 2.20703H4.5C4.08516 2.20703 3.75 2.54219 3.75 2.95703V22.457C3.75 22.8719 4.08516 23.207 4.5 23.207H19.5C19.9148 23.207 20.25 22.8719 20.25 22.457V8.00312C20.25 7.80391 20.1703 7.61172 20.0297 7.47109ZM18.5203 8.34766H14.1094V3.93672L18.5203 8.34766ZM18.5625 21.5195H5.4375V3.89453H12.5156V8.95703C12.5156 9.2181 12.6193 9.46848 12.8039 9.65309C12.9885 9.8377 13.2389 9.94141 13.5 9.94141H18.5625V21.5195ZM11.8125 15.1914H7.5C7.39687 15.1914 7.3125 15.2758 7.3125 15.3789V16.5039C7.3125 16.607 7.39687 16.6914 7.5 16.6914H11.8125C11.9156 16.6914 12 16.607 12 16.5039V15.3789C12 15.2758 11.9156 15.1914 11.8125 15.1914ZM7.3125 12.1914V13.3164C7.3125 13.4195 7.39687 13.5039 7.5 13.5039H16.5C16.6031 13.5039 16.6875 13.4195 16.6875 13.3164V12.1914C16.6875 12.0883 16.6031 12.0039 16.5 12.0039H7.5C7.39687 12.0039 7.3125 12.0883 7.3125 12.1914Z"
      fill={fill}
      className="dark:group-hover:fill-white group-hover:fill-[#3F2193] "
    />
  </svg>
);

export default DocumentsIcon;
